<template>
    <comp-form class="benefit-cat-detail" title="明细" ref="comp_form" submit-api="/gateway/api/synursing/pc/cat/addOrUpdateItem" detail-api="/gateway/api/synursing/pc/cat/selectByCatId" id-key="catId" :submit-before="onSubmitBefore" :data-before="onDataBefore" @on-submit="onReset">
        <template v-slot:form-box="data">
            <ElemFormList v-model="data.form.itemList">
                <template v-slot:formBox="list">
                    <ElemFormItem title="名称" name="catName" required width="50px">
                        <template v-slot:default="item">
                            <Input style="width:500px" v-model.trim="list.item.itemName" :placeholder="item.cfg.ph"></Input>
                        </template>
                    </ElemFormItem>
                    <ElemFormItem title="排序" name="itemSort" required width="50px">
                        <template v-slot:default="item">
                            <Input style="width:500px" type="number" v-model.trim="list.item.itemSort" :placeholder="item.cfg.ph"></Input>
                        </template>
                    </ElemFormItem>
                    <ElemFormItem title="内容" name="sort" required width="50px">
                        <template v-slot:default="item">
                            <Input style="width:500px" rows="16" type="textarea" v-model.trim="list.item.itemContent" :placeholder="item.cfg.ph"></Input>
                        </template>
                    </ElemFormItem>
                </template>
            </ElemFormList>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../jointly/components/CompForm"
import ElemFormItem from "../../jointly/components/elem-form-item.vue"
import ElemFormList from "../../jointly/components/elem-form-list.vue"

export default {
    components: { CompForm, ElemFormItem, ElemFormList },

    methods: {
        /**
         * 打开表单窗口
         */
        open(id, data) {
            this.$refs.comp_form.open(id, data)
        },

        onDataBefore(res) {
            console.log(res)
            return {
                itemList: res,
            }
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        onChangeIcon(e) {
            this.$refs.comp_form.setValue("icon", e.value.url)
        },
    },
}
</script>
